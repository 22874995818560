import Vue from "vue"
import App from "./App.vue"
import Vuex from "vuex"
import store from '@/store/index.js'
import router from "./router/index.js"
import util from "./utils/util.js"
import * as echarts from 'echarts'
import * as mqtt from 'mqtt'
import {
	MQTT_URL
} from './utils/constant.js'

// 重置css的库
import 'normalize.css'

import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"

console.log(process.env);

// mqtt 跟web socket差不多
Vue.prototype.$mqtt = mqtt.connect(MQTT_URL)

Vue.config.productionTip = false
Vue.prototype.$util = util
Vue.prototype.$echarts = echarts

Vue.use(ElementUI)
Vue.use(Vuex)

router.beforeEach((to, from, next) => {
	var token = window.sessionStorage.getItem("token");
	var roles = window.sessionStorage.getItem("roles") ?
		window.sessionStorage.getItem("roles").split(",") : []

	if (to.meta.title) document.title = to.meta.title

	if (to.meta.requireAuth) {
		if (token == "undefined" || token == null || token == "") {
			next({
				path: "/login"
			})
		} else {
			if (to.meta.roles.length > 0) {
				let flag = false

				roles.forEach((role) => {
					if (to.meta.roles.includes(role)) {
						flag = true
					}
				})

				if (flag) {
					next()
				} else {
					next({
						path: "/403"
					})
				}
			} else next()
		}
	} else next()
})

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount("#app")