// 派车管理
export default [{
	path: '/dispatch-vehicle/service',
	component: (resolve) =>
		void require(['../view/dispatch-vehicle/service.vue'], resolve),
	name: 'DispatchVehicleService',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['DispatchVehicleService'],
		title: '派车服务',
	}
},{
	path: '/dispatch-vehicle/management',
	component: (resolve) =>
		void require(['../view/dispatch-vehicle/management.vue'], resolve),
	name: 'VehicleManagement',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['VehicleManagement'],
		title: '车辆管理',
	}
},{
	path: '/dispatch-vehicle/driver',
	component: (resolve) =>
		void require(['../view/dispatch-vehicle/driver.vue'], resolve),
	name: 'Driver',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['Driver'],
		title: '司机列表',
	}
},{
	path: '/dispatch-vehicle/trip',
	component: (resolve) =>
		void require(['../view/dispatch-vehicle/trip.vue'], resolve),
	name: 'Trip',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['Trip'],
		title: '出行安排',
	}
},{
	path: '/dispatch-vehicle/statistics',
	component: (resolve) =>
		void require(['../view/dispatch-vehicle/statistics.vue'], resolve),
	name: 'DispatchStatistics',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['DispatchStatistics'],
		title: '派车统计',
	}
},{
	path: '/dispatch-vehicle/vehicleMaintenance',
	component: (resolve) =>
		void require(['../view/dispatch-vehicle/vehicleMaintenance.vue'], resolve),
	name: 'VehicleMaintenance',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['VehicleMaintenance'],
		title: '车辆维护',
	}
}]
