// 疫苗相关
export default [{
	path: '/vaccine/index',
	component: (resolve) =>
		void require(['../view/vaccine/index.vue'], resolve),
	name: 'Vaccine',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['Vaccine'],
		title: '疫苗接种情况',
	}
}, {
	path: '/vaccine/category',
	component: (resolve) =>
		void require(['../view/vaccine/category.vue'], resolve),
	name: 'VaccineCategory',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['VaccineCategory'],
		title: '疫苗种类',
	}
}]