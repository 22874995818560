// 班车保障
export default [{
	path: '/shuttle-bus/index',
	component: (resolve) =>
		void require(['../view/shuttle-bus/index.vue'], resolve),
	name: 'ShuttleBusList',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['ShuttleBusList'],
		title: '班车保障',
	}
}, {
	path: '/shuttle-bus/category',
	component: (resolve) =>
		void require(['../view/shuttle-bus/category.vue'], resolve),
	name: 'ShuttleBusCategory',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['ShuttleBusCategory'],
		title: '班车类型',
	}
}]