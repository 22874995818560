// 路由和角色权限的store
const app = {
	state: () => ({
		pageTabs: [{
			name: "index",
			route: "/index",
			meta: {
				title: "首页",
			},
		}],
		// 被缓存的页面的名字
		cachedPageNames: [],
		// 当前活跃的路由
		activeIndex: "/index",
		// 权限
		permssions: [],
		// 菜单
		menus: [],
	}),
	mutations: {
		/**
		 * 添加缓存界面
		 * data = route项，即{path,name,meta} 这些，详情看router.js里数据的格式
		 */
		addCachedPage(state, data) {
			if (state.cachedPageNames.includes(data.name)) return
			if (data.meta && data.meta.keepAlive) {
				state.cachedPageNames.push(data.name)
			}
		},
		// 删除缓存界面
		delCachedPage(state, data) {
			const index = state.cachedPageNames.indexOf(data.name)
			if (state.cachedPageNames.length) state.cachedPageNames.splice(index, 1)
		},
		// 添加tabs
		add_tabs(state, data) {
			state.pageTabs.push(data);
		},
		// 删除tabs
		delete_tabs(state, route) {
			let index = 0;
			for (let option of state.pageTabs) {
				if (option.route === route) {
					break;
				}
				index++;
			}
			state.pageTabs.splice(index, 1);
		},
		// 设置当前激活的tab
		set_active_index(state, index) {
			state.activeIndex = index;
		},
		// 设置权限
		set_permssions(state, permssions) {
			state.permssions = permssions;
		},
		// 设置菜单
		set_menus(state, menus) {
			state.menus = menus;
		},
	},
	actions: {
		permssions({
			commit
		}) {
			commit("permssions");
		}
	}
}

export default app
