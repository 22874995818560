import Vue from 'vue'
import VueRouter from 'vue-router'

// 系统管理
import systemRoutes from './system.js'
// 健康档案
import healthRoutes from './user-record.js'
// 基础数据
import baseDataRoutes from './base-data.js'
// 派车管理
import dispatchVehicleRoutes from './dispatch-vehicle.js'
// 安全处理
import safetyRoutes from './safety.js'
// 点餐管理
import OrderRoutes from './order.js'
// 班车保障
import shuttleBusRoutes from './shuttle-bus.js'
// 门诊安排
import outpatientRoutes from './outpatient.js'
// 疫苗相关
import vaccineRoutes from './vaccine.js'
// 打印
import printRoutes from './print.js'

Vue.use(VueRouter)

export default new VueRouter({
  saveScrollPosition: true,
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      }
    }
  },
  routes: [
    ...printRoutes,
    {
      path: '/',
      name: 'main',
      meta: {
        requireAuth: true,
        title: 'HuaRen 云平台',
      },
      redirect: '/index',
      component: (resolve) =>
        void require(['../view/public/Main.vue'], resolve),
      children: [
        ...systemRoutes,
        ...healthRoutes,
        ...baseDataRoutes,
        ...dispatchVehicleRoutes,
        ...safetyRoutes,
        ...OrderRoutes,
        ...shuttleBusRoutes,
        ...outpatientRoutes,
        ...vaccineRoutes,
        {
          path: '/index',
          component: (resolve) =>
            void require(['../view/home/index.vue'], resolve),
          name: 'index',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: [],
            title: '广州智慧康养',
          },
        },
        {
          path: '/notice/index',
          component: (resolve) =>
            void require(['../view/notice/index.vue'], resolve),
          name: 'Notice',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Notice'],
            title: '公告管理',
          }
        },
        {
          path: '/visit/index',
          component: (resolve) =>
            void require(['../view/visit/index.vue'], resolve),
          name: 'Visit',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Visit'],
            title: '来访管理',
          }
        },
        {
          path: '/patrol/index',
          component: (resolve) =>
            void require(['../view/patrol/index.vue'], resolve),
          name: 'Patrol',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Patrol'],
            title: '健康巡诊',
          }
        },
        {
          path: '/see-doctor/index',
          component: (resolve) =>
            void require(['../view/see-doctor/index.vue'], resolve),
          name: 'SeeDoctor',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['SeeDoctor'],
            title: '就诊管理',
          }
        },
        {
          path: '/medication/index',
          component: (resolve) =>
            void require(['../view/medication/index.vue'], resolve),
          name: 'Medication',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Medication'],
            title: '长期用药',
          }
        },
        {
          path: '/medical-history/index',
          component: (resolve) =>
            void require(['../view/medical-history/index.vue'], resolve),
          name: 'MedicalHistory',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['MedicalHistory'],
            title: '病史',
          }
        },
        {
          path: '/maintain/index',
          component: (resolve) =>
            void require(['../view/maintain/index.vue'], resolve),
          name: 'Maintain',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Maintain'],
            title: '维护管理',
          }
        },
        {
          path: '/banner/index',
          component: (resolve) =>
            void require(['../view/banner/index.vue'], resolve),
          name: 'Banner',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Banner'],
            title: 'banner管理',
          }
        },
        {
          path: '/care/index',
          component: (resolve) =>
            void require(['../view/care/index.vue'], resolve),
          name: 'Care',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Care'],
            title: '照护服务',
          }
        },
        {
          path: '/device/index',
          component: (resolve) =>
            void require(['../view/device/index.vue'], resolve),
          name: 'Device',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Device'],
            title: '设备管理',
          }
        },
        {
          path: '/duty/index',
          component: (resolve) =>
            void require(['../view/duty/index.vue'], resolve),
          name: 'Duty',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Duty'],
            title: '值班管理',
          }
        },
        {
          path: '/examination-record/index',
          component: (resolve) =>
            void require(['../view/examination-record/index.vue'], resolve),
          name: 'ExaminationRecord',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['ExaminationRecord'],
            title: '体检记录',
          }
        },
        {
          path: '/in-hospital/index',
          component: (resolve) =>
            void require(['../view/in-hospital/index.vue'], resolve),
          name: 'InHospital',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['InHospital'],
            title: '住院登记',
          }
        },
        {
          path: '/learn/index',
          component: (resolve) =>
            void require(['../view/learn/index.vue'], resolve),
          name: 'Learn',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['Learn'],
            title: '学习教育',
          }
        },
        {
          path: '/application-car/index',
          component: (resolve) =>
            void require(['../view/application-car/index.vue'], resolve),
          name: 'ApplicationCar',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['ApplicationCar'],
            title: '申请用车',
          }
        },
        {
          path: '/punch-clock',
          component: (resolve) =>
            void require(['../view/punch-clock/index.vue'], resolve),
          name: 'PunchClock',
          meta: {
            requireAuth: true,
            keepAlive: true,
            roles: ['PunchClock'],
            title: '巡更打卡',
          }
        }
      ],
    },
    {
      path: '/login',
      component: (resolve) =>
        void require(['../view/login/Login.vue'], resolve),
      name: 'login',
      meta: {
        title: '登录',
      }
    },
    {
      path: '/large-screen',
      component: (resolve) =>
        void require(['../view/large-screen/index.vue'], resolve),
      name: 'LargeScreen',
      meta: {
        requireAuth: true,
        roles: [],
        title: '数据统计',
      }
    },
    {
      path: '/car-statistics',
      component: (resolve) =>
        void require(['../view/large-screen/dispatch-car.vue'], resolve),
      name: 'DispatchCar',
      meta: {
        requireAuth: true,
        roles: [],
        title: '派车统计',
      }
    }
  ]
})