// 健康档案
export default [{
	path: '/user-record/management',
	component: (resolve) =>
		void require(['../view/user-record/management.vue'], resolve),
	name: 'UserRecordManagement',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['UserRecordManagement'],
		title: '档案管理',
	}
}, {
	path: '/user-record/query',
	component: (resolve) =>
		void require(['../view/user-record/query.vue'], resolve),
	name: 'UserRecordQuery',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['UserRecordSearch'],
		title: '档案查询',
	},
}, {
	path: '/user-record/import-history',
	component: (resolve) =>
		void require(['../view/user-record/import-history.vue'], resolve),
	name: 'UserRecordImportHistory',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['UserRecordImportHistory'],
		title: '导入记录',
	},
}, {
	path: '/user-record/export-history',
	component: (resolve) =>
		void require(['../view/user-record/export-history.vue'], resolve),
	name: 'UserRecordExportHistory',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['UserRecordExportHistory'],
		title: '导出记录',
	},
}]
