// 点餐管理
export default [{
	path: '/order/index',
	component: (resolve) =>
		void require(['../view/order/index.vue'], resolve),
	name: 'OrderList',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['OrderList'],
		title: '点餐列表',
	}
}, {
	path: '/order/menu',
	component: (resolve) =>
		void require(['../view/order/menu.vue'], resolve),
	name: 'MenuList',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['MenuList'],
		title: '菜单列表',
	}
}, {
	path: '/order/merchant',
	component: (resolve) =>
		void require(['../view/order/merchant.vue'], resolve),
	name: 'Merchant',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['Merchant'],
		title: '商家管理',
	}
},{
	path: '/order/tel-config',
	component: (resolve) =>
		void require(['../view/order/tel-config.vue'], resolve),
	name: 'TelConfig',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['TelConfig'],
		title: '食堂电话配置',
	}
}]