// 门诊安排
export default [{
	path: '/outpatient/index',
	component: (resolve) =>
		void require(['../view/outpatient/index.vue'], resolve),
	name: 'OutpatientStatistics',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['OutpatientStatistics'],
		title: '门诊统计',
	}
}, {
	path: '/outpatient/watchkeeper',
	component: (resolve) =>
		void require(['../view/outpatient/watchkeeper.vue'], resolve),
	name: 'OutpatientWatchkeeper',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['OutpatientWatchkeeper'],
		title: '值班人员',
	}
}, {
	path: '/outpatient/matters',
	component: (resolve) =>
		void require(['../view/outpatient/matters.vue'], resolve),
	name: 'OutpatientMatters',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['OutpatientMatters'],
		title: '交接事项',
	}
}]