// 上传文件的地址
export const UPLOAD_URL = 'https://kangyang.huarendjk.com/api/upload'

// web socket地址
export const MQTT_URL = 'wss://mqtt.huarendjk.com:8084/mqtt'
// export const WS_URL = 'ws://192.168.1.31:3000'
// web socket消息类型常量
export const msgType = {
	COMMON: 'common', // 普通消息--测试用
	WARNING: 'warning', // 报警
	OFFER: 'offer', // webRTC offer
	ANSWER: 'answer', // webRTC answer
	CANDIDATE: 'candidate', // webRTC candidate
	HANG_UP: 'hangUp', // 挂断
	CALL: 'call', // 呼叫
	REPLY: 'reply', // 答复
}
