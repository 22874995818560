// 基础数据
export default [{
		path: '/base-data/area',
		component: (resolve) =>
			void require(['../view/base-data/area.vue'], resolve),
		name: 'Area',
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ['Area'],
			title: '园区管理',
		}
	}, {
		path: '/base-data/building',
		component: (resolve) =>
			void require(['../view/base-data/building.vue'], resolve),
		name: 'Building',
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ['Building'],
			title: '楼栋管理',
		}
	}, {
		path: '/base-data/floor',
		component: (resolve) =>
			void require(['../view/base-data/floor.vue'], resolve),
		name: 'Floor',
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ['Floor'],
			title: '楼层管理',
		}
	}, {
		path: '/base-data/room',
		component: (resolve) =>
			void require(['../view/base-data/room.vue'], resolve),
		name: 'Room',
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ['Room'],
			title: '房间管理',
		}
	},
	{
		path: '/base-data/bed',
		component: (resolve) =>
			void require(['../view/base-data/bed.vue'], resolve),
		name: 'Bed',
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ['Bed'],
			title: '床位管理',
		}
	}
]
