<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
	}
</script>

<style>
	/* 通用的样式 */
	@import './assets/common.css';
	/* 字体图标 */
	@import "./assets/fonts/iconfont.css";
	/* 封装的flex样式 */
	@import './assets/flex-css/flex.css';
</style>
