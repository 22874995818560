import axios from 'axios'
import router from '../router/index.js'
import {
	Message,
	MessageBox,
} from 'element-ui'

axios.defaults.baseURL = '/api'
axios.defaults.withCredentials = true
axios.defaults.timeout = 1800000

// 请求拦截器
axios.interceptors.request.use(config => {
	const token = sessionStorage.getItem('token')
	
	if (token) {
		config.headers.common['Authorization'] = 'Bearer ' + token
	}
	return config
}, error => {
	return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(
	response => {
		if (response.status === 200) {
			// token过期
			if (response.data.code === 400003) {
				MessageBox.alert('登录过期，请重新登录', '提示', {
					callback() {
						localStorage.removeItem('token')
						location.href = '/login'
					}
				})
			}
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	// 服务器状态码不是2开头的的情况
	error => {
		if (error.response && error.response.status) {
			switch (error.response.status) {
				// 401: 未登录
				// 未登录则跳转登录页面，并携带当前页面的路径
				// 在登录成功后返回当前页面，这一步需要在登录页操作。                
				case 401:
					router.replace({
						path: '/login',
						query: {
							redirect: router.currentRoute.fullPath
						}
					});
					break;

					// 403 token过期
					// 登录过期对用户进行提示
					// 跳转登录页面
				case 403:
					Message('登录过期，请重新登录')
					localStorage.removeItem('token')
					// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
					setTimeout(() => {
						router.replace({
							path: '/login',
							query: {
								redirect: router.currentRoute.fullPath
							}
						})
					}, 1000)
					break;

					// 404请求不存在
				case 404:
					Message('网络请求不存在')
					break;
					// token过期，需要重新登录
				case 400003:
					break;
					// 其他错误，直接抛出错误提示
				default:
					if (error.response.data.message != 'Server Error') {
						Message(error.response.data.message)
					}
			}
			return Promise.reject(error.response)
		}
	}
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params = {}) {
	return new Promise((resolve, reject) => {
		axios.get(url, {
				params: params
			})
			.then(response => {
				if (response.data && response.data.code > 0) {
					Message(response.data.msg)
					reject(response)
				} else {
					resolve(response.data.data);
				}
			})
			.catch(err => {
				reject(err)
			})
	})
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.post(url, data)
			.then(response => {
				if (response.data.code > 0) {
					Message(response.data.msg)
					reject(response)
				} else {
					resolve(response.data)
				}
			}, err => {
				reject(err)
			})
	})
}
/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.patch(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}
/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.put(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}
/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function del(url, data = {}) {
	return new Promise((resolve, reject) => {
		axios.delete(url, data)
			.then(response => {
				resolve(response.data);
			}, err => {
				reject(err)
			})
	})
}

// 上传文件
export function upload(url, data = {}) {
	return new Promise((resolve, reject) => {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
		axios.post(url, data, config).then(res => {
			if (res.data.code === 0) {
				resolve(res.data)
			} else {
				Message(res.data.msg || '上传失败')
			}
		}).catch(err => reject(err))
	})
}

export let host = 'http://kangyang.huarendjk.com'
export default {
	get,
	post,
	patch,
	put,
	del,
	upload,
	host
}
