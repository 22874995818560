// 打印
export default [{
  path: '/print/dispatch-car-service',
  component: (resolve) =>
    void require(['../view/dispatch-vehicle/print/print-service.vue'], resolve),
  meta: {
    requireAuth: true,
    keepAlive: false,
    roles: [],
    title: '派车服务打印预览',
  }
}]