// 系统管理
export default [{
		path: "/system/menus",
		component: (resolve) =>
			void require(["../view/system/menus/Menus.vue"], resolve),
		name: "menus",
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ["Menu"],
			title: "菜单管理",
		},
	},
	{
		path: "/system/account/permissions",
		component: (resolve) =>
			void require(["../view/system/account/Permissions.vue"], resolve),
		name: "permissions",
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ["Permission"],
			title: "权限管理",
		},
	},
	{
		path: "/system/account/roles",
		component: (resolve) =>
			void require(["../view/system/account/Roles.vue"], resolve),
		name: "role",
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ["Role"],
			title: "角色管理",
		},
	},
	{
		path: "/system/account/users",
		component: (resolve) =>
			void require(["../view/system/account/Users.vue"], resolve),
		name: "user",
		meta: {
			requireAuth: true,
			keepAlive: true,
			roles: ["User"],
			title: "用户管理",
		},
	}
]
