// 安全处理
export default [{
	path: '/safety/index',
	component: (resolve) =>
		void require(['../view/safety/index.vue'], resolve),
	name: 'Safety',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['Safety'],
		title: '安全处理',
	}
}, {
	path: '/safety/alarm',
	component: (resolve) =>
		void require(['../view/safety/alarm.vue'], resolve),
	name: 'Alarm',
	meta: {
		requireAuth: true,
		keepAlive: true,
		roles: ['Alarm'],
		title: '一键告警',
	}
}]
